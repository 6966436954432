define("ember-truncate/templates/components/button-toggle", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "HtZFYUqy",
    "block": "[[[8,[39,0],null,[[\"@destinationElement\",\"@renderInPlace\"],[[30,0,[\"_destination\"]],[30,0,[\"_inPlace\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[10,\"button\"],[15,0,[29,[\"truncate-multiline--button \",[30,0,[\"classNames\"]]]]],[15,\"onclick\",[30,0,[\"DOMClick\"]]],[14,4,\"button\"],[12],[1,\"\\n    \"],[18,1,null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"&default\"],false,[\"ember-wormhole\",\"yield\"]]",
    "moduleName": "ember-truncate/templates/components/button-toggle.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});