define("ember-singularity-mixins/mixins/resize-handler", ["exports", "ember"], function (_exports, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * The resize-handler mixin adds an easy-to-use "resize" hook, similar to the
   * default Ember hook for click(). It is only applicable to views/components.
   */

  const RESIZE = "resize";

  // eslint-disable-next-line ember/no-new-mixins
  var _default = _ember.default.Mixin.create({
    unifiedEventHandler: _ember.default.inject.service("unified-event-handler"),
    // The hook for your resize functionality, you must implement this
    [RESIZE]: undefined,
    // Determines if we should fire a resize event on element insertion
    resizeOnInsert: true,
    // Interval in milliseconds at which the resize handler will be called
    // `undefined` by default, can be overridden if custom interval is needed
    resizeEventInterval: undefined,
    // Setups up the handler binding for the resize function
    registerResizeHandlers: _ember.default.on("didInsertElement", function () {
      // Bind 'this' context to the resize handler for when passed as a callback
      let resize = this.get(RESIZE).bind(this);
      this.set(RESIZE, resize);
      this.unifiedEventHandler.register("window", RESIZE, resize, this.resizeEventInterval);
      this._resizeHandlerRegistered = true;
      if (this.resizeOnInsert) {
        // Call the resize handler to make sure everything is in the correct state.
        // We do it after the current render, to avoid any side-effects.
        _ember.default.run.scheduleOnce("afterRender", this, () => {
          resize();
        });
      }
    }),
    // Unbinds the event handler on destruction of the view
    unregisterResizeHandlers: _ember.default.on("willDestroyElement", function () {
      if (this._resizeHandlerRegistered) {
        let resize = this.get(RESIZE);
        this.unifiedEventHandler.unregister("window", RESIZE, resize);
        this._resizeHandlerRegistered = false;
      }
    })
  });
  _exports.default = _default;
});