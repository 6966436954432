define("ember-raf-scheduler/index", ["exports", "@ember/runloop", "@ember/debug"], function (_exports, _runloop, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.scheduler = _exports.default = _exports.Token = _exports.Scheduler = void 0;
  class Token {
    constructor(parent) {
      this._parent = parent;
      this._cancelled = false;
      if (false /* DEBUG */) {
        Object.seal(this);
      }
    }
    get cancelled() {
      return this._cancelled || (this._cancelled = this._parent ? this._parent.cancelled : false);
    }
    cancel() {
      this._cancelled = true;
    }
  }
  _exports.Token = Token;
  function job(cb, token) {
    return function execJob() {
      if (token.cancelled === false) {
        cb();
      }
    };
  }
  class Scheduler {
    constructor() {
      this.sync = [];
      this.layout = [];
      this.measure = [];
      this.affect = [];
      this.jobs = 0;
      this._nextFlush = null;
      this.ticks = 0;
      if (false /* DEBUG */) {
        Object.seal(this);
      }
    }
    schedule(queueName, cb, parent) {
      (false && !(queueName in this) && (0, _debug.assert)(`Attempted to schedule to unknown queue: ${queueName}`, queueName in this));
      this.jobs++;
      let token = new Token(parent);
      this[queueName].push(job(cb, token));
      this._flush();
      return token;
    }
    forget(token) {
      // TODO add explicit test
      if (token) {
        token.cancel();
      }
    }
    _flush() {
      if (this._nextFlush !== null) {
        return;
      }
      this._nextFlush = requestAnimationFrame(() => {
        this.flush();
      });
    }
    flush() {
      let i, q;
      this.jobs = 0;
      if (this.sync.length > 0) {
        (0, _runloop.begin)();
        q = this.sync;
        this.sync = [];
        for (i = 0; i < q.length; i++) {
          q[i]();
        }
        (0, _runloop.end)();
      }
      if (this.layout.length > 0) {
        q = this.layout;
        this.layout = [];
        for (i = 0; i < q.length; i++) {
          q[i]();
        }
      }
      if (this.measure.length > 0) {
        q = this.measure;
        this.measure = [];
        for (i = 0; i < q.length; i++) {
          q[i]();
        }
      }
      if (this.affect.length > 0) {
        q = this.affect;
        this.affect = [];
        for (i = 0; i < q.length; i++) {
          q[i]();
        }
      }
      this._nextFlush = null;
      if (this.jobs > 0) {
        this._flush();
      }
    }
  }
  _exports.Scheduler = Scheduler;
  const scheduler = new Scheduler();
  _exports.scheduler = scheduler;
  var _default = scheduler;
  _exports.default = _default;
});