define("ember-truncate/components/ember-truncate/button-toggle", ["exports", "@ember/component", "@ember/object", "ember-truncate/templates/components/button-toggle"], function (_exports, _component, _object, _buttonToggle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    layout: _buttonToggle.default,
    tagName: '',
    DOMClick: (0, _object.action)(function () {
      const onClick = this.onClick;
      if (typeof onClick === 'function') {
        onClick();
      }
    })
  });
  _exports.default = _default;
});