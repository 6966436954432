(function() {
  function vendorModule() {
    'use strict';

    let React = self['React'];
    return {
      'default': React,
      ...React,
      __esModule: true,
    };
  }

  define('react', [], vendorModule);
})();
