define("ember-truncate/templates/components/truncate-multiline", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "VCqUBAjT",
    "block": "[[[41,[48,[30,1]],[[[41,[30,0,[\"_truncate\"]],[[[1,\"    \"],[18,1,[[28,[37,3],null,[[\"target\",\"button\",\"isTruncated\",\"neededTruncating\"],[[50,\"ember-truncate/truncation-target\",0,null,null],[50,\"ember-truncate/button-toggle\",0,null,[[\"_destination\",\"_inPlace\",\"onClick\"],[[30,0,[\"_buttonDestination\"]],[30,0,[\"_buttonInPlace\"]],[30,0,[\"_toggleTruncate\"]]]]],[30,0,[\"isTruncated\"]],[30,0,[\"neededTruncating\"]]]]]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[18,1,[[28,[37,3],null,[[\"target\",\"button\",\"isTruncated\",\"neededTruncating\"],[[50,\"ember-truncate/truncation-noop\",0,null,null],[50,\"ember-truncate/button-toggle\",0,null,[[\"_destination\",\"_inPlace\",\"onClick\"],[[30,0,[\"_buttonDestination\"]],[30,0,[\"_buttonInPlace\"]],[30,0,[\"_toggleTruncate\"]]]]],[30,0,[\"isTruncated\"]],[30,0,[\"neededTruncating\"]]]]]]],[1,\"\\n\"]],[]]]],[]],[[[41,[30,0,[\"_truncate\"]],[[[1,\"    \"],[8,[39,5],null,null,[[\"default\"],[[[[1,\"\\n      \"],[1,[30,0,[\"text\"]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"],[41,[30,0,[\"neededTruncating\"]],[[[1,\"      \"],[8,[39,6],null,[[\"@_destination\",\"@_inPlace\",\"@onClick\"],[[30,0,[\"_buttonDestination\"]],[30,0,[\"_buttonInPlace\"]],[30,0,[\"_toggleTruncate\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[30,0,[\"seeMoreText\"]]],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[]],null]],[]],[[[1,\"    \"],[1,[30,0,[\"text\"]]],[1,\"\\n\"],[41,[30,0,[\"neededTruncating\"]],[[[1,\"      \"],[8,[39,6],null,[[\"@_inPlace\",\"@onClick\"],[true,[30,0,[\"_toggleTruncate\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[30,0,[\"seeLessText\"]]],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[]],null]],[]]]],[]]]],[\"&default\"],false,[\"if\",\"has-block\",\"yield\",\"hash\",\"component\",\"ember-truncate/truncation-target\",\"ember-truncate/button-toggle\"]]",
    "moduleName": "ember-truncate/templates/components/truncate-multiline.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});