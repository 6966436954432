(function() {
  function vendorModule() {
    'use strict';

    let ReactDOM = self['ReactDOM'];
    return {
      'default': ReactDOM,
      createRoot: ReactDOM.createRoot,
      __esModule: true,
    };
  }

  define('react-dom/client', [], vendorModule);
})();
