define("ember-truncate/components/ember-truncate/truncation-target", ["exports", "@ember/component", "ember-truncate/templates/just-yield"], function (_exports, _component, _justYield) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    layout: _justYield.default,
    classNames: ['truncate-multiline--truncation-target']
  });
  _exports.default = _default;
});