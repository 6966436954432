(function() {
  // Define process.env.NODE_ENV required in the react environment
  window.process = window.process || {};
  window.process.env = window.process.env || {};
  window.process.env.NODE_ENV = 'production';

  function vendorModule() {
    'use strict';

    let SimonReact = self['SimonReact'];
    return {
      'default': SimonReact,
      ...SimonReact,
      __esModule: true,
    };
  }

  define('@vimly-frontend/simon-react', [], vendorModule);
})();
